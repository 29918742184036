import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAlert, positions } from "react-alert";
import InputBox from "../bookingComponents/internalComponents/InputBox";
import PrimaryButton from "../buttons/PrimaryButton";
import { setFreeLogin } from "../../store/actions/authActions";
import router from "next/router";
import Link from "next/link";
import { debounce } from "debounce";
import moengageEvent from "../../helpers/MoengageEventTracking";
import { requestOTPAttributes } from "../../helpers/MoengageAttributeCreators";
import { sendOtp } from "../../store/actions/authActions";
import moment from "moment";

function LoginForm({ stageHandler, isAffiliates = false }) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const WELCOME_TEXT = isAffiliates ? "Welcome Affiliate" : "Welcome Back";
  // ! Regex
  let countryCodeRegex = new RegExp(/^\+[0-9]*$/);
  let numberRegex = new RegExp(/^[0-9]*$/);
  // ! Redux states
  const authOtpRequested = useSelector((state) => state.auth.authOtpRequested);
  const authSourcePage = useSelector((state) => state.auth.authSourcePage);
  const validCountryCodes = useSelector(
    (state) => state.auth.validCountrycodes
  );
  const isNextEduUser = useSelector(
    (state) => state.dashboard_session.isNextEduUser
  );
  // ! Local states
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [email, setEmail] = useState("");
  const [wholePhoneNumber, setWholePhoneNumber] = useState("+91-");
  const [alertPosition, setAlertPosition] = useState(positions.BOTTOM_CENTER);
  const [lastLoginAttempted, setLastLoginAttempted] = useState(0);

  // ! redux states
  const authDestinationPage = useSelector(
    (state) => state.auth.authDestinationPage
  );
  // ! *************************************************************************** LOCAL HELPERS
  // ! isPossiblePhoneNumber
  const isPossiblePhoneNumber = (phone) => {
    phone = phone?.split("-");
    if (phone && phone[0] == "+91") {
      if (phone[1].length === 10) return true;
      return false;
    }
    return true;
  };
  // ! Error state managers
  const phoneNumberError = (phone = "") => {
    if (!phone?.split("-")[1]) return null;
    if (countryCode === "+91") {
      if (phone && phone.split("-")[1].length !== 10)
        return "Please enter valid Phone number";
    } else if (!isPossiblePhoneNumber(phone)) {
      return "Please enter valid Phone number";
    }
    return null;
  };
  const emailError = (email) => {
    return email?.includes("@") || email?.length === 0
      ? null
      : "Enter Valid Email";
  };
  // ! Verify inputs handler
  const verifyInputs = () => {
    alert.removeAll();
    if (countryCode === "+91") {
      if (wholePhoneNumber && wholePhoneNumber.split("-")[1].length !== 10)
        return alert.show("Please enter valid Phone Number", {
          type: "error",
          position: alertPosition,
        });
    } else {
      if (!isPossiblePhoneNumber(wholePhoneNumber))
        return alert.show("Please enter valid Phone Number", {
          type: "error",
          position: alertPosition,
        });
    }
    if (!validCountryCodes.includes(countryCode.slice(1)) && !email?.length > 0)
      return alert.show("Please enter valid Email Address", {
        type: "error",
        position: alertPosition,
      });
    return "verified";
  };

  // ! Debounced functions
  const debouncedSendOtp = debounce(
    () => {
      moengageEvent(
        "Request_Otp",
        requestOTPAttributes("", countryCode === "+91" ? 1 : 2)
      );
      if (countryCode === "+91") {
        let body = { phone_no: `${countryCode}-${phoneNumber}` };
        if (window.location.pathname.includes("/live")) {
          body.context = "join_class";
        }
        dispatch(sendOtp(body));
      } else {
        let body = { email: email };
        if (window.location.pathname.includes("/live")) {
          body.context = "join_class";
        }
        dispatch(sendOtp(body));
      }
    },
    500,
    true
  );

  const submitHandler = () => {
    if (!(moment().unix() - lastLoginAttempted > 15)) {
      alert.show(`Try again in 15 seconds.`, {
        type: "info",
        position: alertPosition,
      });
      return;
    }
    if (verifyInputs() === "verified") {
      //   ! Create otp body
      let body = validCountryCodes.includes(countryCode.slice(1))
        ? {
            phone_no: wholePhoneNumber,
          }
        : {
            email: email,
          };
      if (authSourcePage === "affiliates") {
        debouncedSendOtp();
        stageHandler(2);
        return;
      }
      setLastLoginAttempted(moment().unix());
      dispatch(
        setFreeLogin(
          wholePhoneNumber,
          email,
          body,
          (teacherProfiles=null) => {
            if(teacherProfiles?.length>0) return router.push("/teacher-dashboard")
            return authDestinationPage
              ? router.push(authDestinationPage)
              : router.push("/live");
          },
          validCountryCodes.includes(countryCode.slice(1))
        )
      );
    }
  };
  // ! *************************************************************************** EFFECTS
  // ! Phone number change useeffect
  useEffect(() => {
    if (window.innerWidth > 992) setAlertPosition(positions.TOP_CENTER);
  }, []);
  useEffect(() => {
    if (phoneNumber[0] === "0")
      setPhoneNumber((num) => {
        let numArray = num.split("");
        numArray.shift();
        return numArray.join("");
      });
  }, [phoneNumber]);
  // ! useEffect to make wholePhoneNumber
  useEffect(() => {
    setWholePhoneNumber(`${countryCode}-${phoneNumber}`);
  }, [phoneNumber, countryCode]);
  // ! Effect to listen when the otp is req
  useEffect(() => {
    if (authOtpRequested) stageHandler(2, wholePhoneNumber);
  }, [authOtpRequested]);
  return (
    <div className="spark-auth-form__wrapper spark-signup-form__wrapper">
      <div className="spark-signup-form spark-auth-form">
        <h2 className="spark-auth-form__header">
          <span className="spark-auth-form__header--primary">
            {WELCOME_TEXT}
          </span>
        </h2>

        {authDestinationPage == "/live" && !isNextEduUser ? (
          <p className="spark-auth-form__sub-header">
            To sign up with us
            <span className="spark-auth-form__sub-header_book-free-trial-link-text">
              <Link href="/book-a-trial">
                <a>Book a FREE Trial Class</a>
              </Link>
            </span>
          </p>
        ) : (
          <p className="spark-auth-form__sub-header">
            Oops! Don’t have an account?
            <Link href={`${isAffiliates ? "/affiliate-signup" : "signup"}`}>
              <a>
                <span>Sign up</span>
              </a>
            </Link>
          </p>
        )}

        <div className="spark-auth-form__inputs-container">
          <div className="spark-booking__contact">
            <InputBox
              autoComplete="new-password"
              placeholder="+91"
              boxTitle="Code"
              errorText={null}
              type="text"
              name="country code"
              changeHandler={(val) => {
                if (countryCodeRegex.test(val)) setCountryCode(val);
              }}
              value={countryCode}
            />
            <InputBox
              additionalClassname="number-input"
              autoComplete="on"
              placeholder="Phone (Whatsapp)"
              boxTitle="Whatsapp Number"
              errorText={phoneNumberError(wholePhoneNumber)}
              type="tel"
              name="tel"
              changeHandler={(val) => {
                if (val[0] === "+") {
                  console.log("this", val);
                  setPhoneNumber((num) => num.replace(countryCode, ""));
                  return;
                }
                if (numberRegex.test(val)) setPhoneNumber(val);
              }}
              value={phoneNumber}
            />
          </div>
          {!validCountryCodes.includes(countryCode.slice(1)) && (
            <InputBox
              autoComplete="on"
              placeholder="Email"
              boxTitle="Email"
              errorText={emailError(email)}
              type="email"
              name="email"
              changeHandler={setEmail}
              value={email}
            />
          )}
        </div>

        <PrimaryButton
          buttonText="Log in"
          version="version-1"
          clickHandle={submitHandler}
        />
      </div>
    </div>
  );
}

export default LoginForm;
