import React, { useState, useEffect } from "react";
import LoginForm from "../components/AuthFormComponents/LoginForm";
import OtpVerificationForm from "../components/AuthFormComponents/OtpVerificationForm";
import moengageEvent from "../helpers/MoengageEventTracking";
import Link from "next/link";
import { clickToHomepageAttributes } from "../helpers/MoengageAttributeCreators";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  setAuthDestinationPage,
  setValidCountryCodes,
} from "../store/actions/authActions";
import queryString from "query-string";
import router from "next/router";
import { NextSeo } from "next-seo";
import SEOData from "../store/staticData/SEOData.json";

function Login() {
  const SEO_DATA = SEOData["login"];
  const SEO = {
    title: SEO_DATA.meta_title,
    description: SEO_DATA.meta_description,
  };

  const navigation_urls = {
    s_b: "/dashboard/spelling-bee",
    d: "/dashboard/",
    a_c: "/dashboard/all-classes",
  };

  const dispatch = useDispatch();
  const validCountryCodes = useSelector(
    (state) => state.auth.validCountrycodes
  );
  const authToken = useSelector((state) => state.auth.authToken);
  let queryDetails = queryString.parse(window.location.search);
  const authDestinationPage = useSelector(
    (state) => state.auth.authDestinationPage
  );
  const [currentStage, setCurrentStage] = useState(1);
  const [phoneFromSignup, setPhoneFromSignup] = useState("");
  //! Local helpers
  const stageHandler = (nextStage, phone = "") => {
    setCurrentStage(nextStage);
    setPhoneFromSignup(phone);
  };

  // useEffect to redirect to /dashboard/spelling-bee

  useEffect(() => {
    // ! token present
    if (queryDetails?.t?.length > 0) {
      if (Object.keys(navigation_urls).includes(queryDetails?.n ?? "")) {
        router.push(`/loader/?t=${queryDetails.t}&n=${queryDetails?.n}`);
        dispatch(setAuthDestinationPage(navigation_urls[queryDetails.n]));
      } else {
        router.push(`/loader/?t=${queryDetails.t}&n=d`);
        dispatch(setAuthDestinationPage("/dashboard/all-classes"));
      }
    }
  }, []);

  // !useEffect to fetch country codes.
  useEffect(() => {
    if (validCountryCodes.length > 0) return;
    axios
      .get(process.env.NEXT_PUBLIC_FETCH_COUNTRY_CODES)
      .then((res) => {
        dispatch(setValidCountryCodes(res.data.codes));
      })
      .catch((err) => console.log(err, "error nn"));
  }, []);

  return (
    <div className="spark-auth-screen__wrapper">
      <NextSeo {...SEO} />
      <div className="spark-auth-screen">
        <Link
          href="/"
          onClick={() =>
            moengageEvent("Click_To_Home_Page", clickToHomepageAttributes())
          }
        >
          <a className="spark-auth-screen__spark-logo">
            <img
              src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}sparkLogo.png`}
              alt="Spark Studio"
            />
          </a>
        </Link>
        {currentStage === 1 && <LoginForm stageHandler={stageHandler} />}
        {currentStage === 2 && (
          <OtpVerificationForm
            stageHandler={stageHandler}
            phoneNumber={phoneFromSignup}
          />
        )}
      </div>
    </div>
  );
}

export default Login;
